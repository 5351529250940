import React, { useState, useEffect } from "react"
import Layout from "../../components/layout"
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from "gatsby";
import { DefaultGtag } from '../../utils/misc'
import '../../styles/Article.css'


const mainStyles = {
  display: "flex",
  flexDirection: "column"
}

const ArticlePage = () => {

  useEffect(() => {
    setTimeout(function () {
      // need delay, otherwise not working on firefox...
      window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    }, 100);
  }, [])

  return (
    <Layout>
      <main style={mainStyles}>
        <div className="blogpage">
          <div className="blog">
            <h1 className="title">The High-Level Checklist to Open a Restaurant</h1>
            <p>Opening your own restaurant is no easy feat. An eatery, bistro, or fancy restaurant is more than what meets the eye. What goes on behind the scenes (namely the kitchen walls) is a whole other story. Months of hard work go into the preparation before you can even get it up and running. If you love food, love to cook, or just love to host and entertain, opening your own restaurant could be the ultimate goal.</p>
            <p>Yes, it’s tough, and yes, it will take time, but the process could be infinitely easier with the right plan. How do you transform your vision into a reality? Just follow our 12-step guide to realizing your culinary dream.</p>

            <h2>How to Launch Your Restaurant in 12 Steps</h2>
            <h3>Define Your Concept 💡</h3>
            <p>The first step starts with an idea. The main idea functions as the backbone for the entire restaurant, from the type of cuisine you’d like to serve to the branding, the style of the interior and the decor. It’s very easy to get carried away, but that’s ok! Just let yourself dream a little, but don’t forget to come back down to earth after a while, because an anti-gravity restaurant with floating clouds as seats just isn’t doable at the moment.</p>
            <p>Once you have gathered your ideas and identified a primary theme, you will have a better game plan to carry it out. Here is a quick mini checklist to help you get started.</p>
            <ul className="checkbox">
              <li><input type="checkbox" /> What’s my target demographic? (Families, couples, large parties, etc.)</li>
              <li><input type="checkbox" /> What type of restaurant do I want to open? (Fine dining, casual diner vibe, fast food, family-style, ghost restaurant, etc.)</li>
              <li><input type="checkbox" /> What type of food do I want to serve?  (Fusion, ethnic, good ol’ down-home American, etc.)</li>
              <li><input type="checkbox" /> What service style should I use? (Regular wait staff, buffet, family-style, silver service, etc.)</li>
              <li><input type="checkbox" /> What will the interior look like? (Theme, colors, decor, style)</li>
            </ul>
            <p>Identifying your demographic is the first step because it will pave the way to help you define the subsequent items with more clarity. Here is an example:</p>
            <ol>
              <li>I’d like to cater to families</li>
              <li>The restaurant will be a family-style establishment</li>
              <li>The food will be sushi and Japanese fusion</li>
              <li>Let’s go with a family-style/buffet-type service style</li>
              <li>The interior will be comfy sofa booths, some high tables, with a theme largely centered around muted pastels.</li>
            </ol>
            <p>You should also give your restaurant logo a thought. Sometimes it’s better to leave this to the very end after you have created the atmosphere because it could give you a clearer idea of what the most eye-catching symbol or emblem would be that best represents your brand. There is no harm in giving it a quick consideration for now so you have a template you can edit in the future.</p>
            <p>Determining the demographic first made it easier to figure out the answers to the other questions. Another tip is to make sure the demographic you intend on marketing to also frequent the location you pick, which leads us to the next step.</p>

            <h3>Find the Ideal Location 🌇</h3>
            <p>Downtown is always great. Wherever there is a lot of foot traffic or situated in a bustling area, chances are you would get more exposure. Location is a key component to establishing a restaurant. However, as we said, it won’t matter if you are right in the center of the action if your demographic doesn’t frequent the area.</p>
            <p>One thing is for sure, selecting a location by a busy street will garner more traffic than if you selected a secretive and concealed spot in an unassuming alley. Unless, of course, that’s what you’re going for and it ties into the theme of your restaurant - an exclusive place that requires previous knowledge or invitation to find.</p>
            <p>Secrecy isn’t the best when you’re just starting out, but it’s definitely something you can try once your restaurant is very established.</p>
            <p>An example of a suitable location for our family restaurant above would be:</p>
            <p>A part of downtown that is perhaps near sports stadiums or family-friendly venues such as museums, amusement parks and galleries where families often frequent.</p>

            <h3>Write a Detailed Business Plan 📊</h3>
            <p>A business plan for your restaurant is handy, especially if you are seeking investors or a loan from the bank. It also gives you a clearer picture of whether or not your cost and revenues are reasonable for your ideas in step 1. </p>
            <p>Drafting a business plan will take time and thought, and it can also serve as a compass for how to get from step 1 to step 12. </p>
            <p>The plan should give the bank and potential investors an idea of who you are, your goals, and the strategy you have in mind to get there. Even if you are not planning to get investors, you should absolutely write down your business plan to make sure you understand what your costs will be like, and how you will break even.</p>
            <p>Important information you should include are:</p>
            <ul>
              <li>Name of your restaurant and a short description of the concept and maybe a slogan</li>
              <li>Executive summary</li>
              <li>Target demographic - Who will be your main customer base?</li>
              <li>Competitor analysis - outline your chosen location and the competitors in that area. What sets you apart? What gives you an edge?</li>
              <li>Menu and service options - Breakfast, brunch, linner, dinner, after hours? Outline when and what you will serve.</li>
              <li>Starting costs and budget - this section needs to be done carefully, outlining every penny you need and where it will be allocated.</li>
              <li>Ownership and management structure</li>
              <li>Safety plans - emergency exits, fire extinguishers and alarms will be addressed here</li>
              <li>Staffing - Who do you need to hire and how many? Remember, the entire team will dictate the success or failure of your venture.</li>
              <li>Marketing and advertising plan - How do you plan to gain exposure and get your name out there?</li>
              <li>Revenue projection - How long will it take to break even? Consider your monthly operating costs</li>
            </ul>
            <p>Using supporting material such as visual aids will be very helpful to use during your presentation. Cover all the sections in detail and address what you would want to know if you were asked to invest in a new restaurant venture. What is also very helpful is appealing to your potential investors through their taste buds.</p>
            <p>They say the way to anyone’s heart is through the stomach, so why not give this old saying a try? Prepare a sample menu of your best dishes to cinch the deal.</p>

            <h3>Create the Business Entity 🏢</h3>
            <p>You must also create a business entity for your restaurant. It’s the best move legally because it separates you from the restaurant and reduces your personal risks in case things don’t work out.</p>
            <p>There are different <a href='https://www.nerdwallet.com/article/small-business/business-entity' rel="nofollow" target='_blank'>types of businesses</a>, each with its own pros and cons. The link provided here is for the US, but each country has similar types of business entities. The process to register your business will differ depending on your location and business structure. There are some cases where registration isn’t even needed, but most other times you just need to register the name of your business with local and state governments.</p>
            <p>You can consult a legal representative if you have any questions or would like to know about the best approach.</p>
            <p>In the US, the <a href='https://www.sba.gov/business-guide/launch-your-business/register-your-business' rel="nofollow" target='_blank'>Small Business Administration</a> can provide more helpful information, and you will also need to get an EIN (Employee Identification Number). This is a vital component of your hiring process. You will need an <a href='https://www.irs.gov/businesses/small-businesses-self-employed/employer-id-numbers' rel="nofollow" target="_blank" >EIN</a> in order to set up payroll and start hiring staff.</p>

            <h3>Don’t Forget about Your Licenses 📑</h3>
            <p>Next up is the food service license. This license will be issued if your establishment passes food safety regulations. If you plan to serve alcohol, you will also need a liquor license, which will permit you to serve alcohol. Restaurants that serve alcohol will see higher sales and revenues, but keep in mind that the process for getting this license is a lengthy and expensive one. You should consider getting a resale license which allows you to make non-taxable purchases of the raw ingredients that go into your dishes. You may also need a permit from your city to display any outdoor signs, to open a terrace, or if you plan to play music.</p>

            <h3>Curate an Amazing Menu 🍽</h3>
            <p>Now we get to the part of what a restaurant is all about - the food! The food you serve is the heart and soul of your establishment, so it’s vital to put in a lot of time, patience and effort in curating the menu. </p>
            <p>The menu should appeal to you, and address who you are as well as fit in with the overall theme and style of the location. Luckily, you can work with a professional chef to craft the perfect menu with complementing drinks, desserts and sides. </p>
            <p>The food you serve is more than just a customer draw, as it will also affect how many chefs you hire, the equipment you need, and the suppliers you work with. Don’t worry about creating an extensive list just yet. </p>
            <p>You don’t have to craft a long and detailed menu from the start, in fact, you can introduce a few items in the beginning and fill out the menu as time goes on. This will also keep things fresh and new and keep customers coming back for more.</p>
            <p>Think about the design of the menu. Will it be digital, printed, or both? Having written a detailed business plan before will really benefit you during this step because you will have an idea of what your competitors are doing and what you can do better.</p>
            <p>Not only that, but you can also give them a run for their money with more competitive pricing for more delicious food.</p>

            <h3>Compile a Daily Checklist ✅</h3>
            <p>In the beginning, having a daily checklist will make operations smoother. List what needs to be done on the daily during the opening, service hours, and closing time. Even after you are familiar with daily operations, this list will be very useful for training new hires.</p>

            <h3>Hire Your Staff👨‍🍳👩‍🍳</h3>
            <p>Every business owner is the same in the sense that hiring the best employees is a top priority. The right team will create a lively and healthy work culture with clearly defined tasks. What attracts a job seeker is not only the salary, but also the benefits, the culture and the incentives.</p>
            <p>Make sure you put a lot of thought into what you can offer. Put yourself in their shoes and ask yourself what you would want, but also make sure that what you consider is feasible. After all, you may not be making a profit for a while.</p>
            <p>The next step is to list out all the positions you will need to fill, which include:</p>
            <ul>
              <li>Management - General manager, front of house and back of house managers</li>
              <li>Cleaning staff</li>
              <li>Front-of-house staff - Host/hostess, servers, bussers, bartenders</li>
              <li>Back-of-house staff - Chef, sous chefs, line chefs, and any other position you may need filling</li>
              <li>Accountant</li>
              <li>Marketing team</li>
            </ul>
            <p>You may not need an extensive roster like the above, so it’s important to look over your business plan and concept to determine who you need. </p>
            <p>If you’re offering employee training, then that will widen your pool of candidates. However, experience is key when you’re looking to hire back-of-house staff. You have to be sure who you hire in the kitchen can carry out your culinary vision. Feel free to ask potential candidates to prepare sample dishes on the spot or cook their signature dishes for you to taste.</p>

            <h3>Find Your Supply 👨‍🌾👩‍🌾</h3>
            <p>The next step is to equip your restaurants with the right tools and ingredients to get started. This step includes sourcing the equipment you need and looking for food suppliers. Your chef will be able to tell you what basic equipment he will need as this part will vary depending on the type of restaurant you open.</p>
            <p>For example, those looking to put a focus on specialty desserts from around the world will need professional baking ovens, but not those who intend to deliver Chinese food. Here is a quick checklist of the items to consider for the kitchen and the rest of your restaurant:</p>
            <ul>
              <li>Kitchenware</li>
              <li>Utensils</li>
              <li>Cookware</li>
              <li>Staff uniforms</li>
              <li>Tables and chairs</li>
              <li>Any decor to go into your space</li>
            </ul>

            <p>Most of these items will have a discount if purchased in bulk, and ordering online from a provider is a great way to do that. One extra tip is to look at your budget. Does it make more sense to lease the equipment and think about purchasing later, or will buying it outright be the best move?</p>
            <p>When sourcing ingredient suppliers, it’s best to go local as the food remains fresh. More and more people are aware of unnecessary processing and have grown more health-conscious, so farm-to-table options are preferred.</p>
            <p>We would definitely suggest going with suppliers with USDA and FDA-approved facilities for food safety.  Look at the food you have on the menu and work with your chef to list out all the ingredients you need. Consider everything from the meats to the seasonings. </p>
            <p>Once you have a comprehensive list, the next step is to group them into categories so you have a better idea of storage space. All the meats go into one section, vegetables, dairy, spices, alcohol, etc.</p>
            <p>You have to make sure you have the space to accommodate all the ingredients needed for your projected sales volume. If you don’t have the space, you can work out a delivery schedule with your supplier.</p>
            <p>Now that you have a clear list of items you need, look in your local area for vendors that can satisfy your requirements. You may need to work with 2 or 3 to get everything, but it won’t take up too much of your time with a quick Google search.</p>

            <h3>Set Up Your Operation Systems 💳</h3>
            <p>Once decoration and equipment installation is completed, you can move on to setting up your operating systems. This includes the cashier, card payment system, reservation system if needed, staff planning system, and accounting.</p>
            <p>The process will depend on what you want to incorporate, but we wouldn’t forgo a card payment system since it is most people’s preferred way of spending.</p>

            <h3>Market Your New Restaurant 💻</h3>
            <p>In today’s world, you will want to market your restaurant offline (flyers, newspaper ads, etc.), and online.</p>
            <p>For those who are not well versed in restaurant marketing and have no clue how to even begin to set up a webpage, looking for a one-stop-shop platform to do it all for you could be the answer. </p>
            <p><a href='https://takeaseat.io' rel="noreferrer">Takeaseat</a> will really allow you to sit back and relax with an easy-to-use platform that allows you to customize your very own website and create a digital menu that stays true to your identity while improving customer experience with mobile-friendly versions that come in multiple languages. It can work for any restaurant around the world.</p>
            <p>Creating marketing material for both offline and online is easier than ever. Create flyers to hand out around the city with <a href='https://www.canva.com' target='_blank'>Canva</a>, an intuitive design platform. It can also be used to create great illustrations to distribute via your social media. If you have no idea where to begin with digital designing, there are plenty of talented individuals around the world who can do the work for you on <a href='https://www.upwork.com' rel="nofollow" target='_blank'>Upwork</a> and <a href='https://www.fiverr.com/?utm_source=google&utm_medium=cpc-brand&utm_campaign=G_CA_Brand&utm_term=one-fiverr_(exact)&utm_content=AdID%5E553699614849%5EKeyword%5Efiverr%5EPlacement%5E%5EDevice%5Ec&caid=342920080&agid=26557560640&ad_id=553699614849&kw=fiverr&lpcat=br_general&show_join=true&gclid=CjwKCAiAv_KMBhAzEiwAs-rX1P1rojbwUamvdE1p2zMzSnFzEr83jM-ZCi98cv3TtiD27L6J1Tt-7BoCn1EQAvD_BwE&gclsrc=aw.ds' rel="nofollow" target='_blank'>Fiverr</a>.</p>
            <p>Engagement is a real customer service booster. Make sure to collect feedback from your customers to improve their experience, and find ways to re-engage them and let them know about new offerings from your restaurant. <a href='https://mailchimp.com/' rel="nofollow" target='_blank'>Mailchimp</a> is a great tool to send newsletters.</p>
            <p>Aside from a website, registering your restaurant with Yelp, TripAdvisor, Google Maps and OpenTable will build a stronger online presence and give your business more exposure to people all over the world.</p>
            <p>Do partnerships with local food bloggers, influencers, and businesses to offer discounts and giveaways. Don’t underestimate the power of social media. Run Facebook and Instagram ads and encourage location tags on all of the photos posted by customers. If they do it, maybe throw in a free appetizer or drinks on the house for incentive.</p>
            <p>Word of mouth and online reviews can sometimes make or break a business. Make sure customers have nothing but positive things to say about your restaurant with exceptional customer service, discount codes, promotions, fun events, and great food.</p>

            <h3>Host a Launch Party! 🎊 🎉</h3>
            <p>When it comes time to finally open your doors, do it with a bang! What better way to burst onto the foodie scene than with an awesome soft and/or grand opening launch party?</p>
            <p>The opening is where customers will get the first glance and taste of your long-awaited dream. Have the chef prepare a trial menu that showcases your best dishes. You can make it fun and create miniature finger versions so guests can taste more items without filling up too quickly.</p>
            <p>You should make the launch exclusive with a limited schedule or specialty dishes served only at a specific time. In order to get into the good books of neighbors and surrounding businesses, maybe consider giving them an extra discount.</p>
            <p>Get creative with your launch party! Invite bloggers, influencers, and local reporters you have worked with during the marketing phase to attend as a little thank-you gesture. This is the time to put your best foot forward and make a great impression!</p>

            <br />
            <p>Find the summarized 12 steps in our image below for you to print:</p>
            <img src="/static/media/images/Restaurant-Checklist-Infographic.jpeg" alt="12 steps to launch a restaurants" style={{ width: "100%" }} />
            <p style={{ width: "100%", textAlign: "center", paddingTop: "1rem" }}><a href="/static/media/images/Restaurant-Checklist-Infographic.jpeg" download>Download</a></p>
            <br />
            <p style={{ paddingBottom: "3rem" }}>Your <a href='https://takeaseat.io' rel="noreferrer">Take a Seat</a> team</p>
          </div>
        </div>
      </main>
    </Layout >
  )
}

export default ArticlePage

export const Head = () => <>
  <DefaultGtag />
  <title>The High-Level Checklist to Open a Restaurant</title>
  <meta name="description" content="Opening your own restaurant is no easy feat. An eatery, bistro, or fancy restaurant is more than what meets the eye. What goes on behind the scenes?" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://takeaseat.io/static/media/images/all-in-one-6" />
  <meta property="og:site_name" content="Blog of Take a Seat" />
  <meta property="og:title" content="The High-Level Checklist to Open a Restaurant" />
  <meta property="og:description" content="Opening your own restaurant is no easy feat. An eatery, bistro, or fancy restaurant is more than what meets the eye. What goes on behind the scenes?" />
  <meta property="og:url" content="https://takeaseat.io/article/checklist-to-open-a-restaurant/" />
  <link rel="canonical" href="https://takeaseat.io/article/checklist-to-open-a-restaurant/" />
  {/* <link rel="alternate" hreflang="x-default" href="https://takeaseat.io/article/checklist-to-open-a-restaurant/" />
  <link rel="alternate" hreflang="fr" href="https://takeaseat.io/fr/article/checklist-to-open-a-restaurant/" />
  <link rel="alternate" hreflang="de" href="https://takeaseat.io/de/article/checklist-to-open-a-restaurant/" />
  <link rel="alternate" hreflang="es" href="https://takeaseat.io/es/article/checklist-to-open-a-restaurant/" /> */}
  <meta name="twitter:card" content="summary" />
  <meta name="twitter:image" content="https://pbs.twimg.com/media/FEjpZgbXIAoTpq4?format=jpg&name=4096x4096" />
  <meta name="twitter:site" content="@Takeaseat_io" />
  <meta name="twitter:creator" content="@Takeaseat_io" />
  <meta name="twitter:title" content="The High-Level Checklist to Open a Restaurant" />
  <meta name="twitter:description" content="Opening your own restaurant is no easy feat. An eatery, bistro, or fancy restaurant is more than what meets the eye. What goes on behind the scenes?" />
  <script type="application/ld+json">
    {JSON.stringify({
      "@context": "http://schema.org",
      "@type": "BlogPosting",
      "headline": "The High-Level Checklist to Open a Restaurant",
      "author": "Take a Seat",
      "description": "Opening your own restaurant is no easy feat. An eatery, bistro, or fancy restaurant is more than what meets the eye. What goes on behind the scenes?",
      "url": "https://takeaseat.io/article/checklist-to-open-a-restaurant/",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://takeaseat.io/article/checklist-to-open-a-restaurant/"
      },
      "datePublished": "2022-05-30",
      "dateModified": "2022-09-30",
      "publisher": {
        "@type": "Organization",
        "name": "Take a Seat",
        "logo": {
          "@type": "ImageObject",
          "url": "https://takeaseat.io/media/icons/tas-230427.svg"
        }
      },
      "image": {
        "@type": "ImageObject",
        "url": "https://takeaseat.io/static/media/images/Restaurant-Checklist-Infographic.jpeg"
      }
    })}
  </script>
</>

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["cookies","index", "navigation"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;